<template>
  <b-row>
    <b-col cols="12">
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <b-row>
            <b-col
              cols="12"
              md="3"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Показывать по</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>записей</label>
            </b-col>
            <b-col
              cols="12"
              md="3"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <v-select
                v-model="processedFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="processedOptions"
                class="w-100"
                :reduce="val => val.value"
                placeholder="Выберите действие"
              >
                <template v-slot:option="option">
                  <feather-icon
                    :icon="resolveUserMotivationChanged(option.value)"
                    size="18"
                    class="mr-50"
                  />
                  {{ option.label }}
                </template>
              </v-select>
            </b-col>
            <b-col
              cols="12"
              md="3"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <v-select
                v-model="statusFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                class="w-100"
                :reduce="val => val.value"
                placeholder="Выберите статус"
              />
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Поиск..."
                />
              </div>
            </b-col>
          </b-row>
        </div>
        <b-table
          ref="refListTable"
          class="position-relative"
          :items="fetchItems"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Совпадающих записей не найдено"
          :sort-desc.sync="isSortDirDesc"
        >

          <template #cell(avatar)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.avatar ? data.item.avatar.path : null"
                  :text="avatarText(`${ data.item.firstName } ${ data.item.lastName || '' }`)"
                  :variant="`light-${generateRandomVariant()}`"
                  :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
                />
              </template>
              <b-link
                :to="{ name: 'moderation-bonuses-view', params: { id: data.item.id } }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.firstName }} {{ data.item.lastName || '' }}
              </b-link>
              <small class="text-muted">{{ data.item.phone }}</small>
            </b-media>
          </template>

          <template #cell(isChanged)="data">
            <div class="text-nowrap">
              <feather-icon
                :icon="resolveUserMotivationChanged(data.value)"
                size="18"
                class="mr-50"
                :class="`text-${verifEmailVariant(String(data.value) === '1' ? 0 : 1)}`"
              />
              <span
                v-if="data.value === 1"
                class="align-text-top"
                :class="`text-${verifEmailVariant(String(data.value) === '1' ? 0 : 1)}`"
              > Не обработано</span>
              <span
                v-else
                class="align-text-top"
                :class="`text-${verifEmailVariant(String(data.value) === '1' ? 0 : 1)}`"
              >Обработано</span>
            </div>
          </template>

          <template #cell(status)="data">
            <b-badge :variant="`light-${ resolveUserMotivationStatusVarian(data.value) }`">
              {{ resolveUserMotivationStatus(data.value) }}
            </b-badge>
          </template>

          <template #cell(bonus)="data">
            <div class="text-nowrap">
              <feather-icon
                icon="AwardIcon"
                size="18"
                class="mr-50"
              />
              <span class="align-text-top">{{ data.value }}</span>
            </div>
          </template>

          <template #cell(createdAt)="data">
            {{ formatDate(data.value) }}
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Показано с {{ dataMeta.from }} по {{ dataMeta.to }} из {{ dataMeta.of }} элементов</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BTable, BFormInput, BPagination, BMedia, BAvatar, BLink, BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import { formatDate } from '@/libs/helper'
import {
  generateRandomVariant, resolveUserMotivationChanged, verifEmailVariant, resolveUserMotivationStatus, resolveUserMotivationStatusVarian,
} from '@/utils/UserFilter'
import moderationList from './moderationList'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BFormInput,
    BPagination,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    vSelect,
  },
  setup() {
    const {
      refListTable,
      tableColumns,
      dataMeta,
      fetchItems,

      perPage,
      totalItems,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,

      processedFilter,
      statusFilter,
      processedOptions,
      statusOptions,
    } = moderationList()

    return {
      refListTable,
      tableColumns,
      dataMeta,
      fetchItems,

      perPage,
      totalItems,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,

      processedFilter,
      statusFilter,
      processedOptions,
      statusOptions,

      avatarText,
      formatDate,

      generateRandomVariant,
      resolveUserMotivationChanged,
      verifEmailVariant,
      resolveUserMotivationStatus,
      resolveUserMotivationStatusVarian,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
