import { ref, watch, computed } from '@vue/composition-api'

import usersMotivationRepository from '@/repository/usersMotivationRepository'
import store from '@/store'

export default function moderationList() {
  const refListTable = ref(null)

  const tableColumns = [
    { key: 'avatar', label: 'Пользователь', sortable: true },
    { key: 'isChanged', label: 'Действие', sortable: true },
    { key: 'status', label: 'Статус', sortable: true },
    { key: 'bonus', label: 'Сумма', sortable: true },

    { key: 'motivationTitle', label: 'Вид мотивации', sortable: true },
    { key: 'createdAt', label: 'Создано', sortable: true },
  ]

  const perPage = ref(10)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const processedFilter = ref(1)
  const statusFilter = ref(null)

  const processedOptions = [
    { label: 'Не обработано', value: 1 },
    { label: 'Обработано', value: 0 },
  ]

  const statusOptions = [
    { label: 'Создана заявка', value: 'create' },
    { label: 'Заявка принята', value: 'accept' },
    { label: 'Отказ', value: 'denial' },
    { label: 'Заблокирован', value: 'forbidden' },
  ]

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, processedFilter, statusFilter], () => {
    refetchData()
  })

  const fetchItems = (ctx, callback) => {
    usersMotivationRepository.moderationList({
      query: searchQuery.value,
      page: currentPage.value,
      limit: perPage.value,
      sord: isSortDirDesc.value ? 'desc' : 'asc',
      sidx: sortBy.value,
      isChanged: processedFilter.value,
      status: statusFilter.value,

    })
      .then(response => {
        const { items, count } = response

        callback(items)
        totalItems.value = count
      })
      .catch(error => {
        store.commit('app/SET_ERROR', error)
      })
  }

  return {
    refListTable,
    tableColumns,
    dataMeta,
    fetchItems,

    perPage,
    totalItems,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,

    processedFilter,
    statusFilter,
    processedOptions,
    statusOptions,
  }
}
